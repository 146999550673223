import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import FieldNativeSelector from '@/components/order/FieldNativeSelector.vue';
import Selector from '@/components/share/Selector.vue';
import Field from '@/components/order/Field.vue';
export default defineComponent({
  name: 'FieldAddress',
  components: {
    FieldNativeSelector: FieldNativeSelector,
    Selector: Selector,
    Field: Field
  },
  inject: ['isMobile', 't'],
  props: ['errorMsg', 'name', 'county', 'area', 'address'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var cities = computed(function () {
      return store.state.options.cities.filter(function (item) {
        return item.city_id !== 0;
      }).map(function (item) {
        return {
          label: item.city_name.replace('台', '臺'),
          value: item.city_name.replace('台', '臺')
        };
      });
    });
    var formArea = computed({
      get: function get() {
        return props.area;
      },
      set: function set(val) {
        return emit('update:area', val);
      }
    });
    var formAddress = computed({
      get: function get() {
        return props.address;
      },
      set: function set(val) {
        return emit('update:address', val);
      }
    });

    var onSelect = function onSelect(val) {
      emit('update:county', val);
    };

    return {
      onSelect: onSelect,
      formArea: formArea,
      formAddress: formAddress,
      cities: cities
    };
  }
});