import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9c463358"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "field-address"
};
var _hoisted_2 = {
  class: "field-address-city"
};
var _hoisted_3 = {
  class: "field-address-floor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_field_native_selector = _resolveComponent("field-native-selector");

  var _component_field = _resolveComponent("field");

  var _component_selector = _resolveComponent("selector");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.isMobile ? (_openBlock(), _createBlock(_component_field_native_selector, {
    key: 0,
    options: _ctx.cities,
    selectedValue: _ctx.county,
    onSelect: _ctx.onSelect,
    label: "收件地址"
  }, null, 8, ["options", "selectedValue", "onSelect"])) : (_openBlock(), _createBlock(_component_selector, {
    key: 1,
    class: "van-cell van-address",
    options: _ctx.cities,
    selectedValue: _ctx.county,
    onSelect: _ctx.onSelect,
    searchPlaceholder: "請選擇城市"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_field, {
        selector: "",
        label: "收件地址",
        readonly: "",
        placeholder: "請選擇城市",
        modelValue: _ctx.county,
        showSuccess: false,
        errorMessage: _ctx.errorMsg.county
      }, null, 8, ["modelValue", "errorMessage"])];
    }),
    _: 1
  }, 8, ["options", "selectedValue", "onSelect"])), _createElementVNode("div", _hoisted_2, [_createVNode(_component_field, {
    placeholder: "請選擇鄉鎮市區",
    modelValue: _ctx.formArea,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.formArea = $event;
    }),
    labelClass: "custom-label",
    showSuccess: false,
    errorMessage: _ctx.errorMsg.area
  }, null, 8, ["modelValue", "errorMessage"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_field, {
    placeholder: "請輸入路段/巷弄/門牌號碼/樓層",
    modelValue: _ctx.formAddress,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.formAddress = $event;
    }),
    labelClass: "custom-label",
    showSuccess: false,
    errorMessage: _ctx.errorMsg.address
  }, null, 8, ["modelValue", "errorMessage"])])]);
}