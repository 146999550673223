import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import FieldNativeSelector from '@/components/order/FieldNativeSelector.vue';
import Selector from '@/components/share/Selector.vue';
import Field from '@/components/order/Field.vue';
export default defineComponent({
  name: 'FieldUserNationalities',
  components: {
    FieldNativeSelector: FieldNativeSelector,
    Selector: Selector,
    Field: Field
  },
  inject: ['isMobile', 't'],
  props: {
    modelValue: {},
    label: {
      type: String
    },
    name: {
      type: String
    },
    placeholder: {
      type: String
    },
    errorMessage: {
      type: String
    },
    required: {
      type: Boolean
    },
    showSuccess: {
      default: true,
      type: Boolean
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var nationalities = computed(function () {
      return props.name === 'departure' ? store.state.options.nationalities.filter(function (country) {
        return country.value !== 'TW';
      }) : store.state.options.nationalities || [];
    });
    var nationality = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(val) {
        return emit('change', val);
      }
    });
    var nationalityName = computed(function () {
      var _nationalities$value$;

      return (_nationalities$value$ = nationalities.value.find(function (country) {
        return country.value === nationality.value;
      })) === null || _nationalities$value$ === void 0 ? void 0 : _nationalities$value$.label;
    });

    if (nationalities.value.length === 0) {
      store.dispatch('options/getAllNationalities');
    }

    var onSelect = function onSelect(val) {
      emit('update:modelValue', val);
    };

    return {
      nationalities: nationalities,
      nationality: nationality,
      nationalityName: nationalityName,
      onSelect: onSelect
    };
  }
});