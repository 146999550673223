import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_field_native_selector = _resolveComponent("field-native-selector");

  var _component_field = _resolveComponent("field");

  var _component_selector = _resolveComponent("selector");

  return _ctx.isMobile ? (_openBlock(), _createBlock(_component_field_native_selector, {
    key: 0,
    options: _ctx.nationalities,
    selectedValue: _ctx.nationality,
    onSelect: _ctx.onSelect,
    label: _ctx.label,
    required: "",
    errorMessage: _ctx.errorMessage
  }, null, 8, ["options", "selectedValue", "onSelect", "label", "errorMessage"])) : (_openBlock(), _createBlock(_component_selector, {
    key: 1,
    class: "van-field-custom van-cell",
    options: _ctx.nationalities,
    selectedValue: _ctx.nationality,
    onSelect: _ctx.onSelect,
    searchPlaceholder: _ctx.t('order.please_enter_or_pick_a_nation')
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_field, {
        selector: "",
        readonly: "",
        label: _ctx.label,
        required: _ctx.required,
        placeholder: _ctx.placeholder,
        errorMessage: _ctx.errorMessage,
        modelValue: _ctx.nationalityName,
        showSuccess: _ctx.showSuccess
      }, null, 8, ["label", "required", "placeholder", "errorMessage", "modelValue", "showSuccess"])];
    }),
    _: 1
  }, 8, ["options", "selectedValue", "onSelect", "searchPlaceholder"]));
}