import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a628ae40"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _normalizeClass2;

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_radio = _resolveComponent("van-radio");

  var _component_van_radio_group = _resolveComponent("van-radio-group");

  var _component_van_field = _resolveComponent("van-field");

  return _openBlock(), _createBlock(_component_van_field, {
    class: _normalizeClass((_normalizeClass2 = {}, _defineProperty(_normalizeClass2, 'van-field-custom-radio', true), _defineProperty(_normalizeClass2, 'van-cell--small', !_ctx.withField), _defineProperty(_normalizeClass2, 'van-cell--large', _ctx.withField), _normalizeClass2)),
    required: _ctx.required,
    label: _ctx.label
  }, {
    input: _withCtx(function () {
      return [_createVNode(_component_van_radio_group, {
        modelValue: _ctx.checked,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.checked = $event;
        }),
        direction: "horizontal"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (_ref) {
            var label = _ref.label,
                lang_key = _ref.lang_key,
                value = _ref.value;
            return _openBlock(), _createBlock(_component_van_radio, {
              key: value,
              name: value
            }, {
              icon: _withCtx(function () {
                return [_ctx.checked === value ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  class: "radio-icon",
                  iconName: "radio-selectcircle"
                })) : (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 1,
                  class: "radio-icon",
                  iconName: "radio-unselectcircle"
                }))];
              }),
              default: _withCtx(function () {
                return [_createTextVNode(" " + _toDisplayString(lang_key ? _ctx.t(lang_key) : label), 1)];
              }),
              _: 2
            }, 1032, ["name"]);
          }), 128)), _withDirectives(_createElementVNode("input", {
            class: "radio-input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.fieldValue = $event;
            }),
            placeholder: _ctx.t('order.please_enter_your_specific_illness')
          }, null, 8, _hoisted_1), [[_vShow, _ctx.withField && _ctx.checked], [_vModelText, _ctx.fieldValue]])];
        }),
        _: 1
      }, 8, ["modelValue"])];
    }),
    _: 1
  }, 8, ["class", "required", "label"]);
}